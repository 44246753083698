import {
  Button,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { closeAcceptOfferOTPModal } from "@js/apps/common/components/accept-offer-confirm-modal/confirm-modal";
import { openOfferAcceptedSuccessModal } from "@js/apps/common/components/accept-offer-confirm-modal/success-modal";
import {
  useAcceptHelpOffer,
  useDeclineHelpOffer,
} from "@js/apps/common/hooks/use-help-offer";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { CreatingWalletModalInstance } from "@js/apps/on-ramp/components/creating-wallet-modal";
import { openHelpOfferBuyBTRSTModal } from "@js/apps/on-ramp/components/onramp-modal/help-offer-flow";
import { useCheckCreatingWalletStatus } from "@js/apps/on-ramp/hooks/use-check-creating-wallet-status";
import { ModalInstance } from "@js/components/modal";

export const HelpOfferButtonsPending = ({
  offer,
  isOfferAuthor,
  isMobile,
  type,
  openEditMode,
  onMessageHelper,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { handleDecline, isRejecting } = useDeclineHelpOffer({ offer });

  const { handleAccept, isAccepting } = useAcceptHelpOffer();

  const { isPollingForWalletStatusEnabled } = useCheckCreatingWalletStatus({
    onWalletCreated: (walletInfo) => {
      const walletAddress = walletInfo.safe_wallet_address;
      const transactionUuid = walletInfo.payment_uuid;
      openHelpOfferBuyBTRSTModal({ walletAddress, transactionUuid, offer });
    },
  });

  const disableButtons = isRejecting || isAccepting || !!offer.reported;
  const isDashboardOffer = type === "dashboard";
  const canAcceptOffer =
    !isPollingForWalletStatusEnabled || !Number(offer.budget);

  if (isOfferAuthor && !isDashboardOffer)
    return (
      <Typography
        component="span"
        className="help-offer-action-buttons__status"
        size={isMobile ? "small" : "medium"}
      >
        Offer made
      </Typography>
    );

  if (isOfferAuthor && isDashboardOffer) {
    return (
      <Button
        variant="transparent-border-beige"
        shape="squared"
        onClick={openEditMode}
      >
        Edit
      </Button>
    );
  }

  const handleOfferAccept = () => {
    if (!canAcceptOffer) return;

    handleAccept({
      offer,
      onSuccess: () => {
        ModalInstance.close();
        closeAcceptOfferOTPModal();
        openOfferAcceptedSuccessModal({
          offer,
          onMessageHelper,
        });
      },
    });
  };

  return (
    <>
      {(!isMobile || isDashboardOffer) && (
        <Button
          variant="transparent-border-beige"
          shape="squared"
          className="help-offer-action-buttons__secondary"
          onClick={handleDecline}
          disabled={disableButtons}
        >
          Decline
        </Button>
      )}

      <Tooltip
        disabled={canAcceptOffer}
        title="Your wallet is being created. We will notify you once it is done."
      >
        <span>
          <Button
            variant="primary"
            shape="squared"
            className="help-offer-action-buttons__primary"
            onClick={handleOfferAccept}
            disabled={disableButtons || !canAcceptOffer}
          >
            Accept
          </Button>
        </span>
      </Tooltip>

      <CreatingWalletModalInstance />
    </>
  );
};
