import { forwardRef, useMemo, useState } from "react";
import cs from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import { TextMessageActionMenu } from "@js/apps/messenger/components/message/text-message-action-menu";
import { Linkify } from "@js/components/linkify";
import { IMPLICIT_ANCHOR_WARNING_ATTR } from "@js/services";
import type { Message } from "@js/types/messenger";
import { isValidUrlRegex } from "@js/utils";

import { useMessageRedirectWarning } from "../../hooks/use-message-redirect-warning";

import styles from "./styles.module.scss";

type TextMessageVariants = "soft-grey" | "teal" | "medium-red" | "soft-red";

type TextMessageProps = {
  message: Message;
  variant: TextMessageVariants;
  isOwner?: boolean;
  children?: React.ReactNode;
  linkify?: boolean;
  shouldDisplayRedirectWarning?: boolean;
};

export const TextMessage = ({
  message,
  isOwner,
  variant,
  children,
  linkify = true,
  shouldDisplayRedirectWarning,
}: TextMessageProps): JSX.Element | null => {
  const { anchorWrapperRef } = useMessageRedirectWarning(message);
  const [showMoreButton, setShowMoreButton] = useState(false);

  if (!message.content) {
    return null;
  }

  return (
    <Typography
      component="div"
      className={cs(styles.contentInner, styles[`text-message--${variant}`])}
      onMouseEnter={() => setShowMoreButton(true)}
      onMouseLeave={() => setShowMoreButton(false)}
    >
      <TextMessageActionMenu
        isOwner={isOwner}
        showMoreButton={showMoreButton}
        setShowMoreButton={setShowMoreButton}
        messageId={message.id}
        roomId={message.room}
        messageAuthorName={message.author.public_name}
      />
      <ParsedMessage
        message={message.content}
        linkify={linkify}
        ref={shouldDisplayRedirectWarning ? anchorWrapperRef : null}
      />

      {children}
    </Typography>
  );
};

type ParsedMessageProps = {
  message: string;
  linkify?: boolean;
};

const ParsedMessage = forwardRef<HTMLDivElement, ParsedMessageProps>(
  ({ message, linkify = true }, ref) => {
    const convertRelatedToJobStringToLink = (
      messageContent: string,
    ): string | JSX.Element => {
      const regex = new RegExp(
        SETTINGS.MESSAGE_TO_MATCHER_IN_JOB_CONTEXT_PREFIX + " \\d+",
      );
      const matchedContentWithJobId = messageContent.match(regex);

      if (!matchedContentWithJobId) return messageContent;

      const matchedJobId = matchedContentWithJobId[0].match(/\d+/);
      if (!matchedJobId) return messageContent;

      const jobId = matchedJobId[0];
      const jobTitleString = `${SETTINGS.MESSAGE_TO_MATCHER_IN_JOB_CONTEXT_PREFIX} ${jobId}`;
      const jobLink = `/jobs/${jobId}`;
      const otherPartOfMessage = messageContent.replace(jobTitleString, "");

      return (
        <>
          <a href={jobLink}>{jobTitleString}</a> {otherPartOfMessage}
        </>
      );
    };

    const _message = convertRelatedToJobStringToLink(message);
    const isLinkIncluded = useMemo(() => {
      return message.split(" ").some((word) => isValidUrlRegex(word));
    }, [message]);

    return linkify && isLinkIncluded ? (
      <LinkifiedMessageWithWarning ref={ref}>
        {_message}
      </LinkifiedMessageWithWarning>
    ) : (
      _message
    );
  },
);

type LinkifiedMessageProps = {
  children: string | JSX.Element;
};

const LinkifiedMessageWithWarning = forwardRef<
  HTMLDivElement,
  LinkifiedMessageProps
>(({ children }, ref) => {
  return (
    <div ref={ref}>
      <Linkify
        options={{ attributes: { [IMPLICIT_ANCHOR_WARNING_ATTR]: true } }}
      >
        {children}
      </Linkify>
    </div>
  );
});
