import { useCallback } from "react";
import { SubmissionError } from "redux-form";

import type { AcceptOfferConfirmFormValues } from "@js/apps/common/components/accept-offer-confirm-modal/confirm-modal";
import {
  openAcceptOfferConfirmForm,
  openAcceptOfferOTPModal,
} from "@js/apps/common/components/accept-offer-confirm-modal/confirm-modal";
import { useAcceptHelpOfferMutation } from "@js/apps/give-and-get-help/api";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { openCreatingWalletModal } from "@js/apps/on-ramp/components/creating-wallet-modal";
import { openHelpOfferBuyBTRSTModal } from "@js/apps/on-ramp/components/onramp-modal/help-offer-flow";
import {
  setIsCreatingWalletModalOpen,
  setIsPollingForWalletStatusEnabled,
} from "@js/apps/on-ramp/reducer";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { useUser } from "../use-user";

type UseAcceptHelpOfferConfig = {
  offer: HelpOffer;
  onSuccess: () => void;
};

export const useAcceptHelpOffer = () => {
  const [acceptOffer, { isLoading: isAccepting }] =
    useAcceptHelpOfferMutation();

  const user = useUser();

  const dispatch = useAppDispatch();

  const [initPayment] = useLazyInitCoinbasePaymentQuery();

  const handleOnrampInitialization = useCallback(
    async (offer: HelpOffer) => {
      if (!user?.can_access_onramp) return;

      try {
        const result = await initPayment().unwrap();

        const isWalletReady = result && !result.wallet_creation_in_progress;

        if (isWalletReady) {
          openHelpOfferBuyBTRSTModal({
            offer,
            walletAddress: result.safe_wallet_address,
            transactionUuid: result.payment_uuid,
          });
          return;
        }

        openCreatingWalletModal({
          onOpen: () => {
            dispatch(setIsCreatingWalletModalOpen(true));
          },
          onClose: () => {
            dispatch(setIsCreatingWalletModalOpen(false));
          },
        });

        dispatch(setIsPollingForWalletStatusEnabled(true));
      } catch (err) {
        console.error("Error initializing onramp: ", err);
      }
    },
    [dispatch, initPayment, user?.can_access_onramp],
  );

  const handleAccept = (
    { offer, onSuccess }: UseAcceptHelpOfferConfig,
    isFromOnramp?: boolean,
  ) => {
    try {
      if (+offer.missing_tokens > 0) {
        handleOnrampInitialization(offer);
      } else {
        displayAcceptModal({ offer, onSuccess }, isFromOnramp);
      }
    } catch (e) {
      Snackbar.error("Something went wrong with accepting offer.");
    }
  };

  const displayAcceptModal = (
    { offer, onSuccess }: UseAcceptHelpOfferConfig,
    isFromOnramp?: boolean,
  ) => {
    openAcceptOfferConfirmForm({
      helper: offer.author.user,
      asker: offer.receiver.user,
      category: offer.category.name,
      onSubmit: acceptOfferHandler({ offer, onSuccess }),
      offer: offer,
      isFromOnramp: isFromOnramp,
    });
  };

  const acceptOfferHandler =
    ({ offer, onSuccess }: UseAcceptHelpOfferConfig) =>
    async (values: AcceptOfferConfirmFormValues) => {
      try {
        await acceptOffer({
          offerId: offer.id,
          accepted_budget: offer.budget,
          code: values.code ?? null,
          is_backup_code: values.is_backup_code,
        }).unwrap();
        onSuccess();
        Snackbar.success("Offer accepted");
      } catch (error: any) {
        if (error.data && error.data._error) {
          ModalInstance.close();
          Snackbar.error(error.data._error);
        } else if (error.data && error.data.code_is_required) {
          openAcceptOfferOTPModal();
          if (!error.data.code.includes("2FA code is required.")) {
            Snackbar.error(error.data.code);
          }
        } else {
          Snackbar.error("Something went wrong.");
          throw new SubmissionError(error);
        }
      }
    };

  return {
    handleAccept,
    isAccepting,
  };
};
