import { IconButton } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { HelpOfferActionMenu } from "@js/apps/messenger/components/help-offer-actions";
import type { HelpOffer } from "@js/types/give-and-get-help";

export type HelpOfferActionMenuWrapperProps = {
  offer: HelpOffer;
  isOfferAuthor: boolean;
};
export const HelpOfferActionMenuWrapper = ({
  offer,
  isOfferAuthor,
}: HelpOfferActionMenuWrapperProps): JSX.Element | null => {
  const modifyingOfferAllowed =
    isOfferAuthor && offer.status === ENUMS.HelpOfferStatus.PENDING;
  const enableToRequestRefund =
    !isOfferAuthor &&
    Boolean(Number(offer.budget)) &&
    !offer.refund_requested &&
    offer.status === ENUMS.HelpOfferStatus.ACCEPTED;
  const enableReportOffer = !offer.reported && !offer.refund_issued;
  const isPending = offer.status === ENUMS.HelpOfferStatus.PENDING;

  return (
    <HelpOfferActionMenu
      offer={offer}
      offerReceiverName={offer.receiver.user.first_name}
      isOfferAuthor={isOfferAuthor}
      enableRemoveOfferAction={modifyingOfferAllowed}
      enableToRequestRefund={enableToRequestRefund}
      enableReportOfferAction={enableReportOffer}
      helperFirstName={offer.author.user.first_name}
      refundRequested={!!offer.refund_requested}
      reported={offer?.reported}
      isPending={isPending}
    >
      <IconButton
        className="messenger-action-bar__action-menu-button"
        aria-label="more menu"
        variant="transparent"
        size="medium"
        shape="default"
      >
        <MoreVertIcon />
      </IconButton>
    </HelpOfferActionMenu>
  );
};
