import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { API } from "@js/api";
import type { BidsParamsValues } from "@js/apps/jobs/components/filtering-actions";
import { useBidListFilters } from "@js/apps/jobs/components/filtering-actions";
import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { isErrorWithDataAndRootMessage } from "@js/types/errors";
import type { EmployerBidListBid } from "@js/types/jobs";

import { useFetchBidsQuery } from "../../api";

type UseFilteredBidListReturnType = {
  bidList: EmployerBidListBid[];
  bidFilters: Partial<BidsParamsValues>;
  areFiltersDefault: boolean;
  isBidListLoading: boolean;
  isBidListFetching: boolean;
  refetchBidList: RefetchBidList;
  total: number;
  isLastPage: boolean;
  resetFilters: () => void;
};

const defaultBidList = [];

export const useFilteredBidList = ({
  jobId,
}: {
  jobId: number;
}): UseFilteredBidListReturnType => {
  const { filters: bidFilters, areFiltersDefault } = useBidListFilters();
  const {
    data,
    isLoading: isBidListLoading,
    isFetching: isBidListFetching,
    error,
  } = useFetchBidsQuery(
    { job: jobId, ...bidFilters },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (isErrorWithDataAndRootMessage(error)) {
      Snackbar.error(error.data._error);
    } else if (error) {
      Snackbar.error("Something went wrong, please try different filters.");
    }
  }, [error]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetFilters = () => {
    navigate(window.location.pathname);
  };

  const bidList = !!data && data.results ? data.results : defaultBidList;

  return {
    bidList,
    bidFilters,
    areFiltersDefault,
    isBidListLoading,
    isBidListFetching,
    refetchBidList: () =>
      dispatch(API.util.invalidateTags([{ type: "EmployerBids", id: "LIST" }])),

    total: data?.count || 0,
    isLastPage: !data?.next,
    resetFilters,
  };
};
